import { createContext } from 'react';

import { IConfig } from '../../features/config/types/Config';

export interface IContext extends IConfig {
  baseURL: string,
}

export const defaultState: IContext = {
  baseURL: window.location.protocol + '//' +
    window.location.host + window.location.pathname.replace(/\/$/, ''),
  oidc: {
    // NOTE(longsleep): Support defaults from build environment, mostly useful
    // during development.
    iss: import.meta.env.VITE_CONFIG_OIDC_ISS || '',
    clientID: import.meta.env.VITE_CONFIG_OIDC_CLIENT_ID || '',
    logLevel: import.meta.env.VITE_CONFIG_OIDC_LOG_LEVEL ? parseInt(import.meta.env.VITE_CONFIG_OIDC_LOG_LEVEL, 10) : 2,
  },
  title: {
    base: 'Exchange4all Manage',
  },
};

export const ConfigContext = createContext<IContext>(defaultState);

export default ConfigContext;
