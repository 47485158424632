import React from 'react';

import { Role, HasRoleOp } from './types/Roles';

export function getRoleName(role: Role): string {
  let name;
  switch (role) {
    case Role.SystemAdministrator:
      name = "System Administrator";
      break;
    case Role.GlobalAdministrator:
      name = "Global Administrator";
      break;
    case Role.DomainAdministrator:
      name = "Domain Administrator";
      break;
    case Role.UserAdministrator:
      name = "User Administrator";
      break;
    case Role.User:
      name = "User";
      break;
    case Role.Guest:
      name = "Guest";
      break;
    default:
      name = "Unknown";
  }
  return name;
}

export function getRoleNames(role: Role, op: HasRoleOp = HasRoleOp.Any): string {
  const names: string[] = [];

  for (const [_name, value] of Object.entries(Role)) {
    if (role & (value as Role)) {
      names.push(getRoleName(value as Role));
    }
  }

  let sep = ', ';
  switch (op) {
    case HasRoleOp.Any:
      sep = ' or ';
      break;
    case HasRoleOp.All:
      sep = ' and ';
      break;
  }
  return names.join(sep);
}

export const RoleName = (role: Role, op?: HasRoleOp): JSX.Element => {
  const name = getRoleNames(role, op);
  return <React.Fragment>{name}</React.Fragment>;
}

export default RoleName;
