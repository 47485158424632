import React from "react";

import DomainsIcon from '@mui/icons-material/AlternateEmail';
import OrganizationsIcon from '@mui/icons-material/Business';
import MyAccountIcon from '@mui/icons-material/ManageAccounts';
import UsersIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ConfigurationIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PasswordIcon from '@mui/icons-material/Password';
import ProfileIcon from '@mui/icons-material/AccountBoxOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import AliasIcon from '@mui/icons-material/MailOutline';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

import { IMenuObject } from '../../providers/Menu/Context';
import { Permission } from "../../features/auth/types/Permissions";
import { Role } from "../../features/auth/types/Roles";

export const menus: IMenuObject[] = [
  {
    path: "/",
    primary: "System",
    children: [
      {
        path: "domains",
        primary: "Email domains",
        icon: <DomainsIcon/>,
        requiredRole: Role.SystemAdministrator|Role.GlobalAdministrator,
      },
      {
        path: "organizations",
        primary: "Organizations",
        icon: <OrganizationsIcon/>,
        requiredRole: Role.SystemAdministrator,
      },
      {
        path: "directory",
        primary: "Directory",
        icon: <AccountTreeIcon/>,
        requiredRole: Role.SystemAdministrator,
      },
      {
        path: "storage",
        primary: "Storage",
        icon: <StorageIcon/>,
        requiredRole: Role.SystemAdministrator,
      },
      {
        path: "license",
        primary: "License",
        icon: <LoyaltyIcon/>,
        requiredRole: Role.SystemAdministrator|Role.GlobalAdministrator,
      },
      {
        path: "account",
        primary: "My account",
        icon: <MyAccountIcon/>,
        requiredRole: Role.User,
      },
    ],
  },
  {
    path: "domains/new",
    primary: null,
  },
  {
    path: "domains/:domainID",
    primary: ":domainID",
    children: [
      { path: "domains/:domainID/overview", icon: <DomainsIcon/>, primary: "Overview" },
      {
        path: "domains/:domainID/users",
        primary: "Users",
        icon: <UsersIcon/>,
      },
      {
        path: "domains/:domainID/groups",
        primary: "Groups",
        icon: <GroupsIcon/>,
      },
      {
        path: "domains/:domainID/aliases",
        primary: "Aliases",
        icon: <AliasIcon/>,
        children: [
          { path: "domains/:domainID/aliases/user", primary: "User" },
          {
            path: "domains/:domainID/aliases/domain",
            primary: "Domain",
            requiredRole: Role.SystemAdministrator, // TODO(longsleep): Make available for global admins once backend supports it.
          },
        ],
      },
      { path: "domains/:domainID/conf", icon: <ConfigurationIcon/>, primary: "Configuration" },
      { path: "domains/:domainID/delete", icon: <DeleteIcon/>, primary: "Delete" },
    ],
  },
  {
    path: "domains/:domainID/aliases/new",
    primary: null,
  },
  {
    path: "domains/:domainID/users/new",
    primary: null,
  },
  {
    path: "domains/:domainID/users/:userID",
    primary: ":userID",
    children: [
      { path: "domains/:domainID/users/:userID/overview", icon: <ProfileIcon/>, primary: "Overview" },
      { path: "domains/:domainID/users/:userID/profile", icon: <EditIcon/>, primary: "Profile" },
      { path: "domains/:domainID/users/:userID/conf", icon: <ConfigurationIcon/>, primary: "Configuration" },
      { path: "domains/:domainID/users/:userID/password", icon: <PasswordIcon/>, primary: "Password" },
      { path: "domains/:domainID/users/:userID/delete", icon: <DeleteIcon/>, primary: "Delete" },
    ],
  },
  {
    path: "domains/:domainID/groups/new",
    primary: null,
  },
  {
    path: "domains/:domainID/groups/:groupID",
    primary: ":groupID",
    children: [
      { path: "domains/:domainID/groups/:groupID/overview", icon: <ProfileIcon/>, primary: "Overview" },
      { path: "domains/:domainID/groups/:groupID/conf", icon: <ConfigurationIcon/>, primary: "Configuration" },
      { path: "domains/:domainID/groups/:groupID/delete", icon: <DeleteIcon/>, primary: "Delete" },
    ],
  },
  {
    path: "organizations/new",
    primary: null,
  },
  {
    path: "organizations/:organizationID",
    primary: ":organizationID",
    children: [
      { path: "organizations/:organizationID/overview", icon: <OrganizationsIcon/>, primary: "Overview" },
      { path: "organizations/:organizationID/conf", icon: <ConfigurationIcon/>, primary: "Configuration" },
      {
        path: "organizations/:organizationID/license",
        icon: <LoyaltyIcon/>,
        primary: "License",
        requiredRole: Role.SystemAdministrator,
      },
      { path: "organizations/:organizationID/delete", icon: <DeleteIcon/>, primary: "Delete" },
    ],
  },
  {
    path: "account",
    primary: "My account",
    children: [
      { path: "account/overview", icon: <MyAccountIcon/>, primary: "Overview" },
      {
        path: "account/password",
        icon: <PasswordIcon/>,
        primary: "Password",
        requiredPermission: Permission.Directory_AccessAsUser_All,
      },
    ],
  },
  {
    path: "directory",
    primary: "Directory",
    children: [
      { path: "directory/synchronizations", icon: <CloudSyncIcon/>, primary: "Synchronization" },
    ],
  },
  {
    path: "directory/synchronizations/new",
    primary: null,
  },
  {
    path: "directory/synchronizations/:providerID",
    primary: ":providerID",
    children: [
      { path: "directory/synchronizations/:providerID/overview", icon: <CloudSyncIcon/>, primary: "Overview" },
      { path: "directory/synchronizations/:providerID/settings", icon: <ConfigurationIcon/>, primary: "Settings" },
      { path: "directory/synchronizations/:providerID/delete", icon: <DeleteIcon/>, primary: "Delete" },
    ],
  },
];

export default menus;
