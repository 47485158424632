import http from '../httpConfig';

import { IConfig } from './types/Config';

export const configAPI = http.injectEndpoints({
  endpoints: (build) => ({
    getConfig: build.query<IConfig, string>({
      query: (config) => ({
        url: `${config}`,
      }),
    })
  }),
  overrideExisting: false,
})

export const {
  useGetConfigQuery,
} = configAPI;

export default configAPI;
