import { useContext } from 'react';

import Context, { IContext } from './Context';

export { default as Provider } from './Provider';

export type Config = IContext;

export function useConfig(): IContext {
  return useContext(Context);
}
