import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'

import manageAPI from '../features/httpManage';
import configAPI from '../features/httpConfig';
import { authSlice } from '../features/auth/authSlice';

export const store = configureStore({
  reducer: {
    [manageAPI.reducerPath]: manageAPI.reducer,
    [configAPI.reducerPath]: configAPI.reducer,
    [authSlice.name]: authSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(manageAPI.middleware, configAPI.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
