import React, { Suspense } from "react";

import { SnackbarProvider } from 'notistack';

import { LoadingPage } from '../Page/LoadingPage';
import { Provider as MenuProvider } from '../../providers/Menu';
import { Provider as AuthProvider } from '../../providers/Auth';

import menus from './menus';

export function BaseUI(props: React.PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;

  return (
    <AuthProvider>
      <MenuProvider menus={menus}>
        <Suspense fallback={<LoadingPage/>}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {children}
          </SnackbarProvider>
        </Suspense>
      </MenuProvider>
    </AuthProvider>
  );
}

export default BaseUI;
