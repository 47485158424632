/*
- System Administrator : system level - can do everything
- Global Administrator : organization level (own organization)
- Domain Administrator : domain level (own organization)
- User Administrator : user level (own organization)
- User : user level (own user)
*/

export enum Role {
  // System level.
  SystemAdministrator = 1 << 12,

  // Organization level.
  GlobalAdministrator = 1 << 10,
  DomainAdministrator = 1 << 8,
  UserAdministrator = 1 << 6,

  // Other level.
  User = 1 << 1,
  Guest = 1 << 0,
}

export enum HasRoleOp {
  Any = 0,
  All = 1,
}

export type Roles = Role[];

export type RoleSet = number;

export type CurrentRoleSet = RoleSet;
