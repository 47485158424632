import { useContext, useMemo } from 'react';

import { useRoles, Role } from '../../features/auth/hooks';

import Context, { IContext } from './Context';
export { default as Provider } from './Provider';

export function useMenu(): IContext {
  const context = useContext(Context);
  const { hasRole } = useRoles();

  // Menu requires to have certain roles.
  const hasRequiredRole = hasRole(
    Role.SystemAdministrator |
    Role.GlobalAdministrator |
    Role.DomainAdministrator |
    Role.UserAdministrator |
    Role.User
  );

  return useMemo(() => {
    const { menus, ...other } = context;

    return {
      menus: hasRequiredRole ? menus : [],
      ...other,
    };
  }, [ context, hasRequiredRole ]);
}
