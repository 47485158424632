import React from 'react';

import { Page } from './Page';

export function LoadingPage(): JSX.Element {
  return (
    <Page isLoading withBreadcrumbs={false} withDrawer={false}/>
  )
}

export default LoadingPage;
