import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { App } from './App';
import { store } from './app/store';

const getNonce = (): string | undefined => {
  const nonce = document.querySelector('meta[property="csp-nonce"]')?.getAttribute('content');
  return nonce ? nonce : undefined;
}

const cache = createCache({
  key: 'mui',
  nonce: getNonce(),
  prepend: true,
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <Provider store={store}>
        <App/>
      </Provider>
    </CacheProvider>
  </React.StrictMode>
);
