import { useContext } from 'react';

import Context, { IContext } from './Context';
export { default as Provider } from './Provider';
export type { User, UserManagerSettings } from './Auth';


export function useAuth(): IContext {
  return useContext(Context)
}
