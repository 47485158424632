import React, { useEffect, useCallback } from 'react';

import { ErrorBoundary as BaseErrorBoundary, useErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary'

const UnhandledPromiseRejectionBoundary = (props: React.PropsWithChildren<unknown>): JSX.Element => {
  const { children } = props;

  const { showBoundary }  = useErrorBoundary();

  const handlePromiseRejection = useCallback((event: PromiseRejectionEvent) => {
    console.debug('error boundary promise rejection', event);
    event.preventDefault();

    if ('error' in event.reason && typeof event.reason.error !== 'string') {
      showBoundary(event.reason.error as Error);
    } else {
      showBoundary(event.reason as Error);
    }
  }, [ showBoundary ]);

  useEffect(() => {
    window.addEventListener('unhandledrejection', handlePromiseRejection)
    return () => {
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
    };
  }, [ handlePromiseRejection ]);

  return <React.Fragment>{children}</React.Fragment>;
}

export const ErrorBoundary = (props: React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps>>): JSX.Element => {
  const { children, ...other } = props;

  return (
    <BaseErrorBoundary {...other}>
      <UnhandledPromiseRejectionBoundary>
        {children}
      </UnhandledPromiseRejectionBoundary>
    </BaseErrorBoundary>
  );
}

export default ErrorBoundary;
