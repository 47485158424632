import React from 'react';

import {
  Container,
  CssBaseline,
  Typography,
} from "@mui/material";

export type MessagePageProps = {
  message: string;
};

export function MessagePage(props: MessagePageProps): JSX.Element {
  const { message } = props;

  return (
    <Container sx={{ marginTop: "50vh" }}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          sx={{
            fontSize: "1.3em",
            fontWeight: 300,
          }}
        >
          {message}
        </Typography>
      </Container>
    </Container>
  );
}

export default MessagePage;
