import { useEffect, useRef } from 'react';
import { createSelector } from '@reduxjs/toolkit'
import { useAppSelector } from '../../app/hooks';

import { Permissions } from './types/Permissions';
import { RoleSet, CurrentRoleSet, HasRoleOp } from './types/Roles';
import { IIdentity } from './types/Identity';
import { selectCurrentPermissions, selectCurrentRoles, selectCurrentIdentity } from './authSlice';

export const usePermissions = (): Permissions => useAppSelector(selectCurrentPermissions);

const useRolesSelector = createSelector(
  selectCurrentRoles,
  (roles: CurrentRoleSet) => ({
    roles,
    hasRole: (requiredRole: RoleSet, op = HasRoleOp.Any): boolean => {
      const check = roles & requiredRole;
      if (op === HasRoleOp.Any) {
        // Any bit is good for us.
        return check !== 0;
      } else {
        // All bits must match.
        return check === requiredRole;
      }
    },
  })
)

export const useRoles = () => useAppSelector(useRolesSelector);

export const useIdentity = (): IIdentity => useAppSelector(selectCurrentIdentity);

export const useExactlyOnce = (callback: () => void) => {
  const isTriggered = useRef(false);

  useEffect(() => {
    if (!isTriggered.current) {
      callback();
    }
    return () => {
      isTriggered.current = true;
    }
  }, [ callback ]);
}

export { Permission } from './types/Permissions';

export { Role } from './types/Roles';
