import React from 'react';

import {
  Button,
  Container,
  CssBaseline,
  Stack,
  Typography,
} from "@mui/material";
import {
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'

export type ErrorPageProps = {
  error: unknown;
  resetErrorBoundary?: () => void;
};

export function ErrorPage(props: ErrorPageProps): JSX.Element {
  const { error, resetErrorBoundary } = props;

  let prefix = '';
  let message = '';

  if (typeof error === 'string') {
    message = error;
  } else {
    const record = error as Record<string, unknown>;
    if ('data' in record) {
      const fbqe = error as FetchBaseQueryError;
      message = fbqe.status + ' ' + JSON.stringify(fbqe.data).replace('\\n', '');
    } else {
      if ('status' in record) {
        prefix = ' ' + (record.status as string);
      }
      if ('message' in record) {
        message = (record.message as string);
      }
    }
  }

  return (
    <Container sx={{ marginTop: "43vh" }}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          sx={{
            fontSize: "1.3em",
            fontWeight: 300,
            height: 28,
            "& strong": {
              fontWeight: 400,
            },
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <strong>Error{prefix}</strong>: {message}
        </Typography>
        {resetErrorBoundary && <Stack direction="row" justifyContent="center">
          <Button variant="outlined" onClick={resetErrorBoundary}>Retry</Button>
        </Stack>}
      </Container>
    </Container>
  );
}
