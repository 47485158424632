import React, { useCallback } from 'react';

import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../providers/Auth';
import { useRoles, Role } from '../auth/hooks';

import { useGetProfilePhotoQuery } from '../account/meAPI';

export const AccountMenu = (): JSX.Element | null => {
  const { user, signout } = useAuth();
  const { hasRole } = useRoles();
  const navigate = useNavigate();
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);

  const { data: profilePhoto } = useGetProfilePhotoQuery(null, {
    skip: !user || !hasRole(Role.User),
  });

  const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  if (!user || !user.profile.preferred_username) {
    return null;
  }

  const handleClick = (id?: string) => () => {
    switch (id) {
      case 'account':
        navigate('/account');
        break;
      case 'signout':
        signout();
        break;
      default:
    }

    setAnchorEl(null);
  }

  const profile = user.profile;

  return (<div>
    <IconButton
      size="small"
      aria-haspopup="true"
      onClick={handleOpen}
    >
      <Avatar
        sx={{
          width: 32,
          height: 32,
        }}
        src={profilePhoto ? profilePhoto : undefined}
        alt={profile.name}
      />
    </IconButton>
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClick()}
      sx={{ ml: 0.2 }}
      MenuListProps={{
        dense: false,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      <MenuItem onClick={handleClick('account')}>
        <ListItemText primary={profile.name} secondary={profile.preferred_username} />
      </MenuItem>
      <Divider/>
      <MenuItem onClick={handleClick('signout')}>Sign out</MenuItem>
    </Menu>
  </div>);
}

export default AccountMenu;
