import { useRef, useEffect }  from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`.
export const useAppDispatch = () => useDispatch<AppDispatch>(); /* eslint-disable-line @typescript-eslint/explicit-module-boundary-types */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDocumentTitle = (title: string, options: { skip?: boolean, restoreOnUnmount?: boolean } = {}) => {
  const ref = useRef(document.title);
  const { restoreOnUnmount, skip } = options;

  useEffect(() => {
    if (skip) {
      return;
    }
    document.title = title;
    if (restoreOnUnmount) {
      const previousTitle = ref.current;
      return () => {
        // Set previous title when component leaves screen.
        document.title = previousTitle;
      };
    }
  }, [ title, restoreOnUnmount, skip]);
}
