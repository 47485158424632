import React from 'react';

import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
} from '@mui/material';
import {
  Link as RRDLink,
  LinkProps as RRDLinkProps,
} from 'react-router-dom';

import { useRoutesData } from '../providers/Routes';

function BaseLinkInner(props: RRDLinkProps, ref: React.Ref<HTMLAnchorElement>): JSX.Element {
  const { basename: basepath } = useRoutesData();

  const { to: baseTo, ...other } = props

  let to = baseTo.toString();
  if (to === '//') {
    // Not elegant, but for now fixes the double slash top route.
    to = '/';
  }
  if (to.charAt(0) === '/') {
    to = `${basepath}${to}`;
  }

  return <RRDLink ref={ref} to={to} {...other}/>
}

export const BaseLink = React.forwardRef<HTMLAnchorElement, RRDLinkProps>(BaseLinkInner);

export const Link = BaseLink;

export default BaseLink;

export type RouterLinkProps = MuiLinkProps & RRDLinkProps;

const RouterLinkInner = (props: RouterLinkProps, ref: React.Ref<HTMLAnchorElement>): JSX.Element => <MuiLink ref={ref} {...props} component={BaseLink}/>;

export const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(RouterLinkInner);
