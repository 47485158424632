import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
            textTransform: 'none'
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
            textTransform: 'none'
        },
      },
    },
  },
  typography: {
    body1: {
      fontSize: '0.9rem',
    },
  },
});

export default theme;
