import React, { Suspense, useCallback } from 'react';

import { HashRouter as Router } from 'react-router-dom';
import { ConfirmProvider } from "material-ui-confirm";

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import routes from './app/routes';
import theme from './app/theme';

import './app/dayjs';

import { LoadPage } from './components/LoadPage';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ErrorPage } from './components/ErrorPage';

import { Provider as ConfigProvider } from './providers/Config';
import { Provider as RoutesProvider, useRoutes } from './providers/Routes';

import './app/app.css';

function Routes(): JSX.Element {
  const element = useRoutes();

  return <React.Fragment>{element}</React.Fragment>;
}

export function App(): JSX.Element {
  const onErrorHandler = useCallback(
    (error: Error, info: React.ErrorInfo): void => {
      console.error({error, info});
    },
    [],
  );

  return (
    <ConfigProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfirmProvider>
            <CssBaseline />
            <ErrorBoundary
              FallbackComponent={ErrorPage}
              onError={onErrorHandler}
            >
              <RoutesProvider routes={routes}>
                <Suspense fallback={<LoadPage/>}>
                  <Router>
                    <Routes/>
                  </Router>
                </Suspense>
              </RoutesProvider>
            </ErrorBoundary>
          </ConfirmProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
