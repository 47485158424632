import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { createApi, fetchBaseQuery, FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query/react';

import { RootState } from '../app/store';
import { selectCurrentCredentials } from '../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/v1',
  prepareHeaders: (headers, { getState }) => {
    const credentials = selectCurrentCredentials(getState() as RootState);
    if (credentials.accessToken && credentials.tokenType === 'Bearer') {
      headers.set('Authorization', `${credentials.tokenType} ${credentials.accessToken}`);
    }

    return headers;
  },
});

export default createApi({
  reducerPath: 'manage',
  baseQuery,
  tagTypes: [
    'Domains',
    'Users',
    'Organizations',
    'Storages',
    'UserAliases',
    'DomainAliases',
    'ProfilePhoto',
    'ProfilePhotos',
    'SubscribedSKUs',
    'DomainVerificationDnsRecords',
    'DomainServiceConfigurationRecords',
    'Groups',
    'GroupMembers',
    'OnPremisesSynchronization',
  ],
  refetchOnMountOrArgChange: 30,
  endpoints: () => ({}),
});

export interface IODataValue<T> {
  value: T,
  '@odata.count'?: number,
}

export interface IWithNextLink {
  '@odata.nextLink'?: string,
  isStreaming: boolean,
  streamingError: Error | null,
  streamingAborted?: boolean,
}

export interface IBaseParams {
  id: string,
}

export interface IODataQueryParams {
  '$select'?: string,
  '$filter'?: string,
  '$search'?: string,
}

export interface IPaginationParams {
  maxPageSize?: number,
  page?: number,
  pageSize?: number,
  count?: boolean,
}

export const injectPaginationParams = (params: IODataQueryParams, pagination?: IPaginationParams & IODataQueryParams): Record<string, string> => {
  if (!pagination) {
    return params as Record<string, string>;
  }
  const { pageSize: _pageSize, page: _page, maxPageSize: _maxPageSize, count: _count, ...rest } = pagination;
  const more: Record<string, string> = {};
  if (pagination.pageSize !== undefined) {
    more['$top'] = pagination.pageSize.toString();
    if (pagination.page !== undefined) {
      more['$skip'] = (pagination.pageSize * pagination.page).toString()
    }
  }
  if (pagination.maxPageSize !== undefined) {
    more['$maxpagesize'] = pagination.maxPageSize.toString();
  }
  if (pagination.count) {
    more['$count'] = 'true';
  }
  return {
    '$maxpagesize': '25',
    ...params,
    ...rest,
    ...more,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isAbortError(error: any): error is FetchBaseQueryError {
  if (typeof error === 'object' && error != null && 'error' in error ) {
    if (error.error && error.error.name === 'AbortError') {
      return true;
    }
  }
  return false;
}

export const continueServerDrivenPaging = async <QueryArg, ResultType>(arg: QueryArg & IPaginationParams, api: QueryLifecycleApi<IBaseParams & IPaginationParams & IODataQueryParams, ReturnType<typeof fetchBaseQuery>, IODataValue<Array<ResultType>> & IWithNextLink, string> & BaseQueryApi): Promise<void> => {
  const { updateCachedData, queryFulfilled } = api;
  try {
    // eslint-disable-next-line no-var
    var { data, meta } = await queryFulfilled;
    const controller = new AbortController()
    const signal = controller.signal;
    while (data['@odata.nextLink']) {
      if (meta?.request.signal.aborted) {
        // Abort, when initial request is aborted.
        controller.abort();
        updateCachedData((draft) => {
          draft.isStreaming = false;
          draft.streamingError = null;
          draft.streamingAborted = true;
        });
        return;
      }
      try {
        const response = await baseQuery(data['@odata.nextLink'], {
          ...api,
          signal,
        }, {});
        if (response.error) {
          throw response.error;
        }
        data = await response.data as IODataValue<Array<ResultType>> & IWithNextLink;
        const extra = data.value;
        updateCachedData((draft) => {
          (draft.value as Array<ResultType>).push(...extra);
          draft.isStreaming = true;
          draft.streamingError = null;
        });
      } catch(e: unknown) {
        if (!isAbortError(e)) {
          console.error('failed to fetch @odata.nextLink', e);
        }
        updateCachedData((draft) => {
          draft.isStreaming = false;
          draft.streamingError = e as Error;
        });
        return;
      }
    }
    updateCachedData((draft) => {
      draft.isStreaming = false;
    });
  } catch(e: unknown) {
    if (!isAbortError(e)) {
      console.error('failed to fetch', e)
    }
    updateCachedData((draft?) => {
      if (draft) {
        draft.isStreaming = false;
        draft.streamingError = e as Error;
      }
    });
    return;
  }
}

export {
  skipToken,
}
