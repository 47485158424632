import React, { useMemo, useEffect, useContext, CSSProperties } from 'react';

import {
  Box,
  BoxProps,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  MenuList,
  MenuListProps,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';

import {
  useLocation,
  matchPath,
} from 'react-router-dom';
import { PathMatch, generatePath, resolvePath } from 'react-router';

import { BaseLink, RouterLink } from '../../components/Link';
import { useMenu } from '../../providers/Menu';
import { IMenuObject, Context as MenuContext } from '../../providers/Menu/Context';
import { usePermissions, useRoles, Role } from '../../features/auth/hooks';

type Location = ReturnType<typeof useLocation>;

export type DrawerMenuObject = IMenuObject;

const drawerWidth = 220;


export interface DrawerMenuData {
  match: PathMatch,
  location: Location,
  key: string | null,
  menu: DrawerMenuObject,
}

const Nav = styled(MenuList)({
  '& .MuiListItemIcon-root': {
    minWidth: 36,
  },
  '& .MuiListItemText-inset': {
    paddingLeft: 36,
  },
});

interface MenuNavProps extends Omit<DrawerMenuData, 'key'>, Omit<MenuListProps, 'key'> {
  nested?: boolean,
}

const MenuNav = (props: MenuNavProps) => {
  const {
    menu,
    match,
    location,
    nested,
    ...other
  } = props;

  const { checkContext } = useContext(MenuContext);
  const permissions = usePermissions();
  const { hasRole } = useRoles();
  const canNavigateUp = match.pathname !== '/' && hasRole(Role.GlobalAdministrator | Role.SystemAdministrator);

  return (
    <Nav dense {...other}
      subheader={menu.primary && !nested && <ListSubheader
        disableGutters
        sx={{
          lineHeight: 2.5,
          mb: 0.5,
          pl: .8,
          pr: .8,
          display: 'block',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          '& > a': { color: 'inherit'},
        }}
      >
        {canNavigateUp ? <IconButton
          size="small"
          edge="start"
          component={RouterLink}
          to={resolvePath('..', match.pathname).pathname}
          sx={{
            opacity: 0.5,
            '&:hover': { opacity: 1 },
          }}
          title="Navigate up"
        >
          <SubdirectoryArrowLeftIcon
            fontSize="small"
            sx={{
              transform: 'scale(1, -1)',
              mb: 0.4,
            }}
          />
        </IconButton> : <Box component="span" sx={{pr:1}}></Box> }
        <RouterLink underline="none" to={`/${generatePath(menu.path as string, match.params)}`} title={menu.primary}>{menu.primary}</RouterLink>
      </ListSubheader>
    }>
      {menu.children?.map((item) => {
        if (item.requiredRole && !hasRole(item.requiredRole)) {
          return null;
        }
        if (item.requiredPermission && !permissions.includes(item.requiredPermission)) {
          return null;
        }
        const disabled = checkContext && !checkContext(item);
        const to = `/${generatePath(item.path as string, match.params)}`;
        let locationPathname = location.pathname;
        let toPathname = to;
        if (!item.caseSensitive) {
          locationPathname = locationPathname.toLowerCase();
          toPathname = toPathname.toLowerCase();
        }
        const selected = toPathname === locationPathname;
        const active = selected || (!nested && locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === "/");
        return ([
          <ListItemButton
            key={item.path}
            component={BaseLink}
            to={to}
            disabled={disabled}
            {...item.props}
            selected={nested && selected}
          >
            {item.icon && <ListItemIcon>
              {item.icon}
            </ListItemIcon>}
            <ListItemText inset={!item.icon} primaryTypographyProps={{sx: {fontWeight: active ? 'bold' : null, color: active ? 'primary.main' : null}}}>{item.primary}</ListItemText>
            {item.secondaryAction && <ListItemSecondaryAction>
              <item.secondaryAction path={item.path} match={match} location={location}/>
            </ListItemSecondaryAction>}
          </ListItemButton>,
          active && item.children && <MenuNav key={item.path + "_"} menu={item} match={match} location={location} disablePadding nested/>,
        ]);
      })}
    </Nav>
  );
}

const getDrawerMenuMatch = ({
    currentSection,
    location,
    pathSection,
    menus,
}: {
  currentSection: string,
  location: Location,
  pathSection: string,
  menus: DrawerMenuObject[],
}): DrawerMenuData | null => {
  let menu: DrawerMenuData | null = null;
  menus.some(({ primary: userProvidedDrawer, caseSensitive, path, ...other }) => {
    if (!path) {
      return false;
    }

    const match = matchPath({
      caseSensitive,
      path,
      end: false,
    }, pathSection);

    if (match) {
      if (match.pathname.endsWith('/new') && !pathSection.endsWith('/new')) {
        // Skip premature match for /new locations when the actual requested path is not ending with /new.
        return false;
      }

      if (userProvidedDrawer === null) {
        return true;
      }

      menu = {
        match,
        location,
        key: match.pathname,
        menu: {
          primary: userProvidedDrawer ? generatePath(userProvidedDrawer, match.params) : currentSection,
          caseSensitive,
          path,
          ...other,
        },
      };
      return false;
    }

    return false;
  });

  return menu;
}

export const getDrawerMenu = ({
  menus,
  location,
}: {
  menus: DrawerMenuObject[],
  location: Location,
}): React.ReactNode => {
  const { pathname } = location;
  let menu: DrawerMenuData | null = null;

  pathname
  .split('?')[0]
  // Split pathname into sections.
  .split('/')
  // Reduce over the sections and call `getBreadcrumbMatch()` for each section.
  .reduce(
    (previousSection: string, currentSection: string, index: number) => {
      // Combine the last route section with the currentSection.
      // For example, `pathname = /1/2/3` results in match checks for
      // `/1`, `/1/2`, `/1/2/3`.
      const pathSection = !currentSection
        ? '/'
        : `${previousSection}/${currentSection}`;

      // Ignore trailing slash or double slashes in the URL
      if (pathSection === '/' && index !== 0) {
        return '';
      }

      const match = getDrawerMenuMatch({
        currentSection,
        location,
        pathSection,
        menus,
      });
      if (match) {
        menu = match;
      }

      return pathSection === '/' ? '' : pathSection;
  });

  if (menu === null) {
    return null;
  }
  return <MenuNav {...(menu as DrawerMenuData)}/>;
}

export const useDrawerMenu = (
  menus?: DrawerMenuObject[],
): React.ReactNode => {
  const location = useLocation();
  return useMemo(() => (getDrawerMenu({
    menus: menus || [],
    location,
  })), [menus, location]);
}

export type DrawerProps = BoxProps;

export function Drawer(props: DrawerProps): JSX.Element {
  const { mobileOpen, toggleDrawer, menus } = useMenu();
  const { pathname } = useLocation();
  const drawer = useDrawerMenu(menus);

  useEffect(() => {
    // Auto close mobile drawer after navigation.
    return () => {
      if (mobileOpen && toggleDrawer) {
        toggleDrawer();
      }
    }
  }, [pathname, mobileOpen, toggleDrawer])

  const handleDrawerToggle = () => {
    if (toggleDrawer) {
      toggleDrawer();
    }
  }

  return (
    <Box
      component="nav"
      {...props}
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
        ...props.sx,
      }}
    >
      <MuiDrawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          BackdropProps: {
            sx: {
              top: 56,
            },
          },
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            top: 56,
          },
        }}
      >
        {drawer}
      </MuiDrawer>
      <MuiDrawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        PaperProps={{
          sx: {
            top: (theme) => (theme.mixins.toolbar['@media (min-width:600px)'] as CSSProperties).minHeight,
          },
        }}
        open
      >
        <div>
          <Divider/>
          {drawer}
        </div>
      </MuiDrawer>
    </Box>
  )
}

export default Drawer;
