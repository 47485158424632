import React from 'react';

import { Outlet, Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../providers/Auth';
import { LoadPage } from '../../components/LoadPage';

import { IState } from './types/State';
import { Role, HasRoleOp } from './types/Roles';
import { useRoles } from './hooks';
import { getRoleNames } from './RoleName';

export type PrivateOutletProps = {
  requiredRole: Role,
  requiredRoleOp?: HasRoleOp,
}

export const PrivateOutlet = (props: PrivateOutletProps): JSX.Element | null => {
  const { requiredRole, requiredRoleOp } = props;
  const { isAuthenticated, isBlocked, user } = useAuth();
  const location = useLocation();
  const { roles, hasRole } = useRoles();
  const authenticated = isAuthenticated();

  if (isBlocked()) {
    return <LoadPage/>;
  }

  const state: IState = {};
  const currentState = location.state as IState;

  if (authenticated) {
    const error = !hasRole(requiredRole, requiredRoleOp);
    if (!error) {
      return <Outlet/>;
    }

    const as = user?.profile.preferred_username ? `as "${user.profile.preferred_username}" ` : '';

    state.error = error;
    state.message = `Access ${as}denied. The "${getRoleNames(requiredRole, requiredRoleOp)}" role is required.`;
    console.debug(state.message, roles, location.pathname);
  } else {
    if (currentState && currentState.continue) {
      return <Navigate
        to={currentState.continue}
        replace={true}
      />
    }
  }

  // Inject continue information.
  state.continue = {
    pathname: location.pathname,
    search: location.search,
    hash: location.hash,
  };

  // Inject end information if any.
  if (currentState && currentState.end) {
    state.end = currentState.end;
  }

  return <Navigate
    to="/signin-required"
    replace={true}
    state={state}
  />;
}

PrivateOutlet.defaultProps = {
  requiredRole: Role.SystemAdministrator,
  requiredRoleOp: HasRoleOp.Any,
};

export default PrivateOutlet;
