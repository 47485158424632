export enum UserPrivilege {
  // Keep in sync with e4a-system/doc/privilege_bits.md.
  POP3_IMAP = 1 << 0,
  SMTP      = 1 << 1,
  CHGPASSWD = 1 << 2,
  NO_MAPI   = 1 << 3,
  NO_AS     = 1 << 4,
  NO_WEB    = 1 << 5,
  USRADM    = 1 << 6,
  DOMADM    = 1 << 8,
  ORGADM    = 1 << 10,
  SYSADM    = 1 << 12,
}

export enum DomainPrivilege {
  // Keep in sync with e4a-system/doc/privilege_bits.md.
  VERYFYD = 1 << 2,
  SUBROOT = 1 << 10,
}
