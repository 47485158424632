import React from 'react';

import { useLocation } from 'react-router-dom';

import MessagePage from '../../components/MessagePage';

import { IState } from './types/State';
import PrivateOutlet, { PrivateOutletProps } from './PrivateOutlet';

export const RequestOutlet = (props: PrivateOutletProps): JSX.Element => {
  const location = useLocation();

  const { state: currentState } = location;
  if (!currentState || !(currentState as IState).continue) {
    return <MessagePage message="You can close this window now."/>;
  }

  return <PrivateOutlet {...props}/>;
}

export default RequestOutlet;
