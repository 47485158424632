import { createContext } from 'react';

import { CreateSigninRequestArgs, CreateSignoutRequestArgs } from 'oidc-client-ts';

import { User } from './Auth';

export type ISigninArgs = Pick<CreateSigninRequestArgs, 'display' | 'prompt' | 'state'>;

export type ISigninSilentArgs = Omit<ISigninArgs, 'prompt'>;

export type ISignoutArgs = Pick<CreateSignoutRequestArgs, 'state'>;

export interface IContext {
  signin: (args?: ISigninArgs) => Promise<void>
  signinSilent: (args?: ISigninSilentArgs) => Promise<User | null>
  signout: (args?: ISignoutArgs) => Promise<void>
  removeUser: () => Promise<void>
  withRequest: (request: string) => Promise<void>
  isAuthenticated: () => boolean
  isBlocked: () => boolean
  processSigninResponse: () => Promise<User | null>
  processSigninSilentResponse: () => Promise<void>
  processSignoutResponse: () => Promise<unknown>
  user: User | null
}

export const defaultState: IContext = {
  signin: async (_args: ISigninArgs = {}) => undefined,
  signinSilent: async (_args: ISigninSilentArgs = {}) => null,
  signout: async (_args: ISignoutArgs = {}) => undefined,
  removeUser: async () => undefined,
  withRequest: async (_request: string) => undefined,
  isAuthenticated: () => false,
  isBlocked: () => false,
  processSigninResponse: async () => null,
  processSigninSilentResponse: async () => undefined,
  processSignoutResponse: async () => undefined,
  user: null,
};

export const Context = createContext<IContext>(defaultState);

export default Context;
