import { useContext } from 'react';

import { useRoutes as useRoutesBase, RouteObject } from 'react-router-dom';

import Context, { IContext } from './Context';
export { default as Provider } from './Provider';

export function useRoutesData(): IContext {
  return useContext(Context);
}

export function useRoutes(): ReturnType<typeof useRoutesBase> {
  const { routes, basename } = useContext(Context)
  return useRoutesBase(routes as RouteObject[], basename);
}
