import React, { FC, useMemo } from 'react';

import { deepmergeCustom } from "deepmerge-ts";

import { useGetConfigQuery } from '../../features/config/configAPI';
import { IConfig } from '../../features/config/types/Config';
import { useDocumentTitle } from '../../app/hooks';

import Context, { IContext, defaultState } from './Context';

const getRuntimeConfigPath = (): string | undefined => {
  const runtimeConfig = document.querySelector('link[data-property="runtime-config"]')?.getAttribute('href');
  return runtimeConfig ? runtimeConfig : undefined;
}

const mergeRuntimeConfig = (config?: IConfig): IContext => {
  if (!config) {
    return defaultState;
  }
  return deepmergeCustom({
    mergeArrays: false,
  })(defaultState, config as IContext);
}

export type Props = React.PropsWithChildren<unknown>

export const ConfigProvider: FC<Props> = ({
  children,
}: Props) => {
  const runtimeConfig = useMemo(getRuntimeConfigPath, []);
  const { data: config, isLoading, isError } = useGetConfigQuery(runtimeConfig as string, {
    skip: !runtimeConfig,
  });

  const effectiveConfig = useMemo(() => {
    return mergeRuntimeConfig(config);
  }, [ config ])

  const baseTitle = effectiveConfig?.title?.base || '';
  useDocumentTitle(baseTitle, {
    restoreOnUnmount: false,
    skip: !baseTitle,
  });

  if (isLoading) {
    return null;
  } else if (isError) {
    return <div>Failed to load runtime configuration.</div>;
  }

  return (
    <Context.Provider value={effectiveConfig}>
      {children}
    </Context.Provider>
  )
};

export default ConfigProvider;
