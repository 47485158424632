import React, { lazy } from "react";

import { Navigate, Outlet, useParams as useParamsBase } from 'react-router-dom';

import { ErrorPage as NotFound } from '../components/ErrorPage';

import { BaseUI } from '../containers/BaseUI/BaseUI';
import { IRouteObject } from '../providers/Routes/Context';
import { IndexPage } from '../features/auth/IndexPage';
import { PrivateOutlet } from '../features/auth/PrivateOutlet';
import { RequestOutlet } from '../features/auth/RequestOutlet';
import { Role } from '../features/auth/types/Roles';

const DomainsIndex = lazy(() => import(/* webpackChunkName: "f-d-didx" */ '../features/domains/DomainsIndex'));
const NewDomain = lazy(() => import(/* webpackChunkName: "f-d-newd" */ '../features/domains/NewDomain'));
const DeleteDomain = lazy(() => import(/* webpackChunkName: "f-d-deld" */ '../features/domains/DeleteDomain'));
const DomainOverview = lazy(() => import(/* webpackChunkName: "f-d-dovv" */ '../features/domains/DomainOverview'));
const DomainConf = lazy(() => import(/* webpackChunkName: "f-d-dcnf" */ '../features/domains/DomainConf'));
const DomainOutlet = lazy(() => import(/* webpackChunkName: "f-d-dolt" */ '../features/domains/DomainOutlet'));
const UsersIndex = lazy(() => import(/* webpackChunkName: "f-d-uidx" */ '../features/domains/UsersIndex'));
const NewUser = lazy(() => import(/* webpackChunkName: "f-d-newu" */ '../features/domains/NewUser'));
const DeleteUser = lazy(() => import(/* webpackChunkName: "f-d-delu" */ '../features/domains/DeleteUser'));
const UserOverview = lazy(() => import(/* webpackChunkName: "f-d-uovv" */ '../features/domains/UserOverview'));
const UserPassword = lazy(() => import(/* webpackChunkName: "f-d-upwd" */ '../features/domains/UserPassword'));
const UserProfile = lazy(() => import(/* webpackChunkName: "f-d-uprof" */ '../features/domains/UserProfile'));
const UserConf = lazy(() => import(/* webpackChunkName: "f-d-ucnf" */ '../features/domains/UserConf'));
const GroupsIndex = lazy(() => import(/* webpackChunkName: "f-d-gidx" */ '../features/domains/GroupsIndex'));
const NewGroup = lazy(() => import(/* webpackChunkName: "f-d-newg" */ '../features/domains/NewGroup'));
const DeleteGroup = lazy(() => import(/* webpackChunkName: "f-d-delg" */ '../features/domains/DeleteGroup'));
const GroupOverview = lazy(() => import(/* webpackChunkName: "f-d-govv" */ '../features/domains/GroupOverview'));
const GroupConf = lazy(() => import(/* webpackChunkName: "f-d-gcnf" */ '../features/domains/GroupConf'));

const AccountOverview = lazy(() => import(/* webpackChunkName: "f-a-aovv" */ '../features/account/AccountOverview'));
const AccountPassword = lazy(() => import(/* webpackChunkName: "f-a-apwd" */ '../features/account/AccountPassword'));

const OrganizationsIndex = lazy(() => import(/* webpackChunkName: "f-o-oidx" */ '../features/organizations/OrganizationsIndex'));
const NewOrganization = lazy(() => import(/* webpackChunkName: "f-o-newo" */ '../features/organizations/NewOrganization'));
const DeleteOrganization = lazy(() => import(/* webpackChunkName: "f-o-newo" */ '../features/organizations/DeleteOrganization'));
const OrganizationOverview = lazy(() => import(/* webpackChunkName: "f-o-oovv" */ '../features/organizations/OrganizationOverview'));
const OrganizationConf = lazy(() => import(/* webpackChunkName: "f-o-ocnf" */ '../features/organizations/OrganizationConf'));
const OrganizationLicensesIndex = lazy(() => import(/* webpackChunkName: "f-o-osidx" */ '../features/organizations/LicensesIndex'));

const StoragesIndex = lazy(() => import(/* webpackChunkName: "f-s-sidx" */ '../features/storages/StoragesIndex'));

const UserAliasesIndex = lazy(() => import(/* webpackChunkName: "f-a-uidx" */ '../features/aliases/UserAliasesIndex'));
const NewUserAlias = lazy(() => import(/* webpackChunkName: "f-a-newua" */ '../features/aliases/NewUserAlias'));
const DomainAliasesIndex = lazy(() => import(/* webpackChunkName: "f-a-uidx" */ '../features/aliases/DomainAliasesIndex'));
const NewDomainAlias = lazy(() => import(/* webpackChunkName: "f-a-newua" */ '../features/aliases/NewDomainAlias'));

const CallbackOutlet = lazy(() => import(/* webpackChunkName: "f-auth-cbo" */ '../features/auth/CallbackOutlet'));
const AuthorizeCallback = lazy(() => import(/* webpackChunkName: "f-auth-acb" */ '../features/auth/AuthorizeCallback'));
const EndsessionCallback = lazy(() => import(/* webpackChunkName: "f-auth-ecb" */ '../features/auth/EndsessionCallback'));
const SilentRefreshCallback = lazy(() => import(/* webpackChunkName: "f-auth-scb" */ '../features/auth/SilentRefreshCallback'));
const SigninPage = lazy(() => import(/* webpackChunkName: "f-auth-sip" */ '../features/auth/SigninPage'));

const RequestCallback = lazy(() => import(/* webpackChunkName: "f-auth-rcb" */ '../features/auth/RequestCallback'));
const RequestSetPassword =lazy(() => import(/* webpackChunkName: "f-a-rspwd" */ '../features/account/RequestSetPassword'));

const ECPOutlet = lazy(() => import(/* webpackChunkName: "f-ecp-o" */ '../features/ecp/ECPOutlet'));

const LicensesIndex = lazy(() => import(/* webpackChunkName: "f-s-lidx" */ '../features/licenses/LicensesIndex'));

const SynchronizationsIndex = lazy(() => import('../features/synchronizations/SynchronizationsIndex'));
const OnPremisesSynchronizationOverview = lazy(() => import('../features/synchronizations/OnPremisesSynchronizationOverview'));
const NewOnPremisesSynchronization = lazy(() => import('../features/synchronizations/NewOnPremisesSynchronization'));
const DeleteOnPremisesSynchronization = lazy(() => import('../features/synchronizations/DeleteOnPremisesSynchronization'));
const OnPremisesSynchronizationSettings = lazy(() => import('../features/synchronizations/OnPremisesSynchronizationSettings'));

export type Params = {
  domainID: string,
  userID: string,
  groupID: string,
  organizationID: string,
  providerID: string,
}

export function useParams(): Params {
  const params = useParamsBase();

  return {
    domainID: params.domainID || '',
    userID: params.userID || '',
    groupID: params.groupID || '',
    organizationID: params.organizationID || '',
    providerID: params.providerID || '',
  }
}

export const routes: IRouteObject[] = [
  {
    path: "/",
    element: <BaseUI><Outlet/></BaseUI>,
    children: [
      {
        path: "/",
        element: <IndexPage/>,
      },
      {
        path: "domains",
        breadcrumb: "Domains",
        element: <PrivateOutlet requiredRole={Role.SystemAdministrator|Role.GlobalAdministrator}/>,
        children: [
          { index: true, element: <DomainsIndex/> },
          { path: "new", element: <NewDomain/>, breadcrumb: "New" },
          {
            path: ":domainID",
            element: <DomainOutlet/>,
            children: [
              { index: true, element: <Navigate to="overview" replace/> },
              { path: "overview", element: <DomainOverview/>, breadcrumb: null },
              { path: "delete", element: <DeleteDomain/>, breadcrumb: "Delete" },
              { path: "conf", element: <DomainConf/>, breadcrumb: "Configuration" },
              {
                path: "users",
                breadcrumb: "Users",
                children: [
                  { index: true, element: <UsersIndex/> },
                  { path: "new", element: <NewUser/>, breadcrumb: "New" },
                  {
                    path: ":userID",
                    children: [
                      { index: true, element: <Navigate to="overview" replace/> },
                      { path: "overview", element: <UserOverview/>, breadcrumb: null },
                      { path: "delete", element: <DeleteUser/>, breadcrumb: "Delete" },
                      { path: "password", element: <UserPassword/>, breadcrumb: "Password" },
                      { path: "profile", element: <UserProfile/>, breadcrumb: "Profile" },
                      { path: "conf", element: <UserConf/>, breadcrumb: "Configuration" },
                    ],
                  },
                ],
              },
              {
                path: "groups",
                breadcrumb: "Groups",
                children: [
                  { index: true, element: <GroupsIndex/> },
                  { path: "new", element: <NewGroup/>, breadcrumb: "New" },
                  {
                    path: ":groupID",
                    children: [
                      { index: true, element: <Navigate to="overview" replace/> },
                      { path: "overview", element: <GroupOverview/>, breadcrumb: null },
                      { path: "delete", element: <DeleteGroup/>, breadcrumb: "Delete" },
                      { path: "conf", element: <GroupConf/>, breadcrumb: "Configuration" },
                    ],
                  },
                ],
              },
              {
                path: "aliases",
                breadcrumb: "Aliases",
                children: [
                  { index: true, element: <Navigate to="user" replace/> },
                  {
                    path: "user",
                    breadcrumb: "User",
                    children: [
                      { index: true, element: <UserAliasesIndex/> },
                      { path: "new", element: <NewUserAlias/>, breadcrumb: "New" },
                    ]
                  },
                  {
                    path: "domain",
                    breadcrumb: "Domain",
                    children: [
                      { index: true, element: <DomainAliasesIndex/> },
                      { path: "new", element: <NewDomainAlias/>, breadcrumb: "New" },
                    ]
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "account",
        breadcrumb: "My Account",
        element: <PrivateOutlet requiredRole={Role.User}/>,
        children: [
          { index: true, element: <Navigate to="overview" replace/> },
          { path: "overview", breadcrumb: "Overview", element: <AccountOverview/> },
          { path: "password", breadcrumb: "Password", element: <AccountPassword/> },
        ],
      },
      {
        path: "organizations",
        breadcrumb: "Organizations",
        element: <PrivateOutlet requiredRole={Role.SystemAdministrator}/>,
        children: [
          { index: true, element: <OrganizationsIndex/> },
          { path: "new", element: <NewOrganization/>, breadcrumb: "New" },
          {
            path: ":organizationID",
            children: [
              { index: true, element: <Navigate to="overview" replace/> },
              { path: "overview", element: <OrganizationOverview/>, breadcrumb: null },
              { path: "delete", element: <DeleteOrganization/>, breadcrumb: "Delete" },
              { path: "conf", element: <OrganizationConf/>, breadcrumb: "Configuration" },
              { path: "license", element: <OrganizationLicensesIndex/>, breadcrumb: "License" },
            ]
          },
        ],
      },
      {
        path: "directory",
        breadcrumb: "Directory",
        element: <PrivateOutlet requiredRole={Role.SystemAdministrator}/>,
        children: [
          { index: true, element: <Navigate to="synchronizations" replace/> },
          {
            path: "synchronizations",
            breadcrumb: "Synchronization",
            children: [
              { index: true, element: <SynchronizationsIndex/> },
              { path: "new", element: <NewOnPremisesSynchronization/>, breadcrumb: "New" },
              {
                path: ":providerID",
                children: [
                  { index: true, element: <Navigate to="overview" replace/> },
                  { path: "overview", element: <OnPremisesSynchronizationOverview/>, breadcrumb: null },
                  { path: "delete", element: <DeleteOnPremisesSynchronization/>, breadcrumb: "Delete" },
                  { path: "settings", element: <OnPremisesSynchronizationSettings/>, breadcrumb: "Settings" },
                ]
              },
            ],
          }
        ],
      },
      {
        path: "storage",
        breadcrumb: "Storage",
        element: <PrivateOutlet requiredRole={Role.SystemAdministrator}/>,
        children: [
          { index: true, element: <StoragesIndex/> },
        ],
      },
      {
        path: "license",
        breadcrumb: "License",
        element: <PrivateOutlet requiredRole={Role.SystemAdministrator|Role.GlobalAdministrator}/>,
        children: [
          { index: true, element: <LicensesIndex/> },
        ],
      },
      {
        path: "oidc",
        children: [
          { path: "authorize-callback", element: <AuthorizeCallback/> },
          { path: "request-callback", element: <RequestCallback/> },
        ],
      },
      {
        path: "by-request",
        element: <RequestOutlet requiredRole={Role.Guest}/>,
        breadcrumb: null,
        children: [
          {
            path: ":requestID",
            breadcrumb: null,
            children: [
              { path: "set-password", breadcrumb: null, element: <RequestSetPassword/> },
            ],
          },
        ],
      },
      {
        path: "ecp",
        element: <ECPOutlet />,
        breadcrumb: null,
      },
      {
        path: "signin-required",
        element: <SigninPage/>,
      },
    ],
  },
  {
    path: "_",
    children: [
      {
        path: "oidc",
        element: <CallbackOutlet/>,
        children: [
          { path: "silent-refresh", element: <SilentRefreshCallback/> },
          { path: "endsession-callback", element: <EndsessionCallback/> },
        ],
      },
    ],
  },
  { path: "*", element: <NotFound error={{name: 'Not found', message: 'Oops sorry, we could not find anything here.', status: 404} as Error}/> },
];

export default routes;
