import React from 'react';

import { useConfig } from '../providers/Config';

import logo from '../e4a-logo.svg';

export function Logo(props: Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'alt'>): JSX.Element {
  const config = useConfig()

  const alt = config.title?.base || '';
  const src = config.logo?.url || logo;

  return <img
    alt={alt}
    src={src}
    {...props}
  />
}

export default Logo;
