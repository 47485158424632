import React, { useCallback } from 'react';

import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Fade,
  IconButton,
  LinearProgress,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';

import { makeStyles } from '../../app/makeStyles';

import { useMenu } from '../../providers/Menu';

import { RouterLink } from '../../components/Link';
import Logo from '../../components/Logo';

import { AccountMenu as AccountMenuButton } from '../../features/account/AccountMenu';

import { Breadcrumbs } from './Breadcrumbs';
import { Drawer, DrawerProps } from './Drawer';
import { ElevationScroll } from './ElevationScroll';
import { ScrollTop } from './ScrollTop';

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: '100%',
      minHeight: '100vh',
      minWidth: 360,
      paddingBottom: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column',
    },
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      maxHeight: 64,
    },
    logo: {
      verticalAlign: 'middle',
      height: 28,
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...theme.mixins.toolbar as Record<string, unknown>,
    },
    loading: {
      height: 1,
      marginTop: -1,
      zIndex: theme.zIndex.drawer+2,
    },
    content: {
      flex: 1,
      display: 'flex',
    },
  }),
);

export type PageProps = {
  children?: React.ReactNode;

  pageTitle?: string;
  isFetching?: boolean;
  isLoading?: boolean;

  withDrawer?: boolean;
  withBreadcrumbs?: boolean;
  DrawerProps?: DrawerProps;
};

export function Page(props: PageProps): JSX.Element {
  const {
    children,

    pageTitle,
    isFetching,
    isLoading,

    withDrawer,
    withBreadcrumbs,
    DrawerProps: drawerProps,
  } = props;

  const { classes } = useStyles();
  const { toggleDrawer, menus } = useMenu();

  const handleDrawerToggle = useCallback(() => {
    if (toggleDrawer) {
      toggleDrawer();
    }
  }, [ toggleDrawer ]);

  return (
    <Box className={classes.root}>
      <ElevationScroll>
        <AppBar
          color="inherit"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar>
            <Button
              component={RouterLink}
              to="/"
              sx={{ml: { xs: -1, sm: -2 }}}
            >
              <Logo className={classes.logo}/>
            </Button>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="inherit" noWrap>
              {pageTitle}
            </Typography>
            <AccountMenuButton/>
          </Toolbar>
          <Fade
            in={isFetching || isLoading}
            style={{
              transitionDelay: (isFetching || isLoading) ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
            <LinearProgress className={classes.loading}/>
          </Fade>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbar} id="back-to-top-anchor"/>
      <div className={classes.content}>
        {withDrawer && <Drawer {...drawerProps}/>}
        <Container maxWidth="lg" component="main" sx={{ width: 'auto', flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
          {withBreadcrumbs && <Stack
            direction="row"
            spacing={0}
          >
            <Stack
              direction="row"
              sx={{ display: { sm: 'none' }, mr: 2, ml: -.3 }}
              divider={<Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 1.5 }}/>}
            >
              {menus.length && <IconButton
                onClick={handleDrawerToggle}
                size="small"
              >
                <MenuIcon/>
              </IconButton>}
              <div></div>
            </Stack>
            <Breadcrumbs sx={{pt: 1, pb: .6, typography: 'caption'}}/>
          </Stack>}

          {!isLoading && children}
        </Container>
      </div>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  )
}

Page.defaultProps = {
  headerTitle: '',

  withBreadcrumbs: true,
  withDrawer: true,
}

export default Page;
