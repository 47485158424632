import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import jwtDecode from "jwt-decode";

import { RootState } from '../../app/store';
import { User } from '../../providers/Auth';

import { Permissions, Permission, AllPermissions } from './types/Permissions';
import { Role, CurrentRoleSet } from './types/Roles';
import { AuthClaims, IdentityClaims } from './types/Claims';
import { UserPrivilege } from './types/Privilege';
import { IIdentity } from './types/Identity';

export interface AuthState {
  credentials: {
    accessToken?: string,
    tokenType?: string,
  },
  identity: IIdentity,
  claims: Record<string, unknown>,
  permissions: Permissions,
  roles: CurrentRoleSet,
}

const initialState: AuthState = {
  credentials: {},
  claims: {},
  identity: {},
  permissions: [],
  roles: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload }: PayloadAction<Pick<User, 'token_type' | 'access_token'> | null>,
    ) => {
      state.credentials = payload ? {
        tokenType: payload.token_type,
        accessToken: payload.access_token,
      } : initialState.credentials;
      state.claims = payload ? jwtDecode<Record<string, unknown>>(payload.access_token) : initialState.claims;
      state.identity = state.claims ? identityFromClaims(state.claims["lg.i"] as IdentityClaims) : initialState.identity;
      state.permissions = state.claims ? permissionsFromClaims(state.claims as AuthClaims) : initialState.permissions;
      state.roles = state.claims ? rolesFromClaims(state.claims as AuthClaims, state.claims["lg.i"] as IdentityClaims) : initialState.roles;
    },
  },
});

export const {
  setCredentials,
} = authSlice.actions;

function permissionsFromClaims(claims?: AuthClaims): Permissions {
  const upb = claims?.upb || -1;
  const permissions: Permissions = [];
  let withPermissions = false;
  if (claims && claims.scp) {
    for (const scope of claims.scp.split(' ')) {
      if (AllPermissions.includes(scope as Permission)) {
        permissions.push(scope as Permission)
        withPermissions = true;
      }
    }
  }
  if (upb >= 0) {
    if (upb & UserPrivilege.CHGPASSWD) {
      if (!withPermissions) {
        permissions.push(Permission.Directory_AccessAsUser_All);
      } else {
        // Remove permission, if the user does not have the required flag.
        const i = permissions.indexOf(Permission.Directory_AccessAsUser_All, 0);
        if (i > -1) {
          permissions.splice(i, 1);
        }
      }
    }
  }
  return permissions;
}

function rolesFromClaims(claims?: AuthClaims, identityClaims?:  IdentityClaims): CurrentRoleSet {
  let upb = claims?.upb || null;
  let crs: CurrentRoleSet = 0;
  if (upb !== null) {
    // NOTE(longsleep): Support two different modes here. When the upb value
    // is 0 or a positive value, the User role is added implicitly. If it is a
    // negative value, the User role is not added implicitly. This allows great
    // flexibility for various use cases.
    if (upb >= 0) {
      // Everyone has the user role.
      crs |= Role.User;
    }
    upb = Math.abs(upb);
    if (upb & UserPrivilege.SYSADM) {
      crs |= Role.SystemAdministrator
    }
    if (upb & UserPrivilege.ORGADM) {
      crs |= Role.GlobalAdministrator;
    }
    if (upb & UserPrivilege.DOMADM) {
      crs |= Role.DomainAdministrator;
    }
    if (upb & UserPrivilege.USRADM) {
      crs |= Role.UserAdministrator;
    }
  }
  if (identityClaims && identityClaims.gu) {
    crs |= Role.Guest;
  }
  return crs;
}

function identityFromClaims(claims?: IdentityClaims): IIdentity {
  return {
    id: claims?.id,
    userPrincipalName: claims?.un,
    domain: claims?.un?.split('@').pop(),
    organization: undefined, // TODO(longsleep): Get own organization somehow.
  };
}

export default authSlice.reducer;

export const selectCurrentCredentials = (state: RootState): AuthState["credentials"] => state.auth.credentials;

export const selectCurrentPermissions = (state: RootState): AuthState["permissions"] => state.auth.permissions;

export const selectCurrentRoles = (state: RootState): AuthState["roles"] => state.auth.roles;

export const selectCurrentIdentity = (state: RootState): AuthState["identity"] => state.auth.identity;
