import {
  UserManager,
  UserManagerSettings,
  Log,
  User as BaseUser,
} from 'oidc-client-ts';

import { Config } from '../Config';

import { IContext, ISigninArgs, ISigninSilentArgs, ISignoutArgs } from './Context';

export enum CALLBACK_PATH {
  AUTHORIZE_NORMAL = "/oidc/authorize-callback",
  ENDSESSION_NORMAL = "/_/oidc/endsession-callback",
  SILENT_REFRESH = "/_/oidc/silent-refresh",
}

export type User = BaseUser & {
  profile: BaseUser["profile"] & {
    name: string,
    preferred_username: string,
    email: string,
    email_verified: boolean,
  },
};

export type {
  UserManagerSettings,
};

export class Auth implements IContext {
  private readonly _userManager: UserManager;
  private _user: User | null;
  private _blocked = false;
  private _auto = true;
  private _debug = false;

  public onUserChange?: (user: User | null, old: User | null) => void;

  private static getDefaultSettings(config?: Config, defaultSettings?: UserManagerSettings): UserManagerSettings {
    const appBaseURL = config ? config.baseURL : '';
    const settings: UserManagerSettings = defaultSettings ? { ...defaultSettings } : {
      authority: '',
      client_id: '',
      scope: 'openid profile email E4A.Manage.Default',
      redirect_uri: `${appBaseURL}/#${CALLBACK_PATH.AUTHORIZE_NORMAL}?`,
      post_logout_redirect_uri: `${appBaseURL}/#${CALLBACK_PATH.ENDSESSION_NORMAL}?`,
      silent_redirect_uri: `${appBaseURL}/#${CALLBACK_PATH.SILENT_REFRESH}?`,
      response_type: 'code',
      response_mode: 'fragment',
      loadUserInfo: true,
      accessTokenExpiringNotificationTimeInSeconds: 120,
      automaticSilentRenew: true,
      validateSubOnSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      monitorSession: true,
      extraQueryParams: {},
      extraTokenParams: {},
    };

    if (config?.oidc) {
      const { iss: authority, clientID: client_id } = config.oidc;
      Object.assign(settings, {
        authority,
        client_id,
      });
    }

    // Add essential defaults when they missing.
    if (!settings.authority) {
      // Auto generate issuer with current host.
      settings.authority = 'https://' + window.location.host;
    }
    if (!settings.client_id) {
      settings.client_id = 'e4a-manage-' + encodeURI(appBaseURL);
    }

    return settings;
  }

  constructor(config?: Config, defaultSettings?: UserManagerSettings) {
    this._userManager = new UserManager(Auth.getDefaultSettings(config, defaultSettings));
    this._user = null;

    this._userManager.clearStaleState();

    const logLevel = config?.oidc?.logLevel !== undefined ? config.oidc.logLevel : Log.WARN;
    Log.setLogger(console);
    Log.setLevel(logLevel);
    this._debug = logLevel > Log.WARN;

    this._userManager.events.addAccessTokenExpiring(() => {
      if (this._debug) {
        console.debug('oidc token expiring');
      }
    });

    this._userManager.events.addAccessTokenExpired(() => {
      if (this._debug) {
        console.debug('oidc token expired');
      }
      this._userManager.removeUser();
    });

    this._userManager.events.addUserLoaded((user) => {
      if (this._debug) {
        console.debug('oidc user loaded', user);
      }
      const oldUser = this._user;
      if (oldUser && oldUser.profile.sub !== user.profile.sub) {
        // Huh we received another user, this should not happen so lets clear
        // our local stuff and pretend nothing happened.
        console.warn('oidc remove user as the user has changed');
        this._auto = false;
        this._userManager.removeUser();
        return;
      }
      this._auto = true;
      this.onUser(user as User);
    });

    this._userManager.events.addUserUnloaded(() => {
      if (this._debug) {
        console.debug('oidc user unloaded');
      }
      delete this._userManager.settings.extraQueryParams['request'];
      this.onUser(null);
    });

    this._userManager.events.addSilentRenewError((error) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const reason = (error as any).error as string;
      console.warn('oidc silent renew error', reason);
      switch (reason) {
        case 'interaction_required':
        case 'login_required':
          this._auto = false;
          this._userManager.removeUser();
          return;
        default:
          break;
      }

      setTimeout(() => {
        if (!this._auto) {
          return;
        }
        if (this._debug) {
          console.debug('oidc retrying silent renew');
        }
        this._userManager.getUser().then(user => {
          if (this._debug) {
            console.debug('oidc retrying silent renew of user', user);
          }
          if (user && (!user.expired)) {
            this._userManager.stopSilentRenew();
            this._userManager.startSilentRenew();
          } else {
            console.warn('oidc remove user as silent renew has failed to renew in time');
            this._userManager.removeUser();
          }
        });
      }, 5000);
    });

    this._userManager.events.addUserSignedOut(() => {
      if (this._debug) {
        console.debug('oidc user signed out');
      }
      this._auto = false;
      this.onUser(null);
    });
  }

  protected onUser(user: User | null): void {
    const old = this._user;
    this._user = user;
    if (this.onUserChange) {
      this.onUserChange(user, old);
    }
  }

  protected getUser = async (): Promise<User | null> => {
    return await this._userManager.getUser() as User;
  }

  public get user(): User | null {
    return this._user;
  }

  public signin = async (args: ISigninArgs = {}): Promise<void> => {
    if (this._blocked) {
      return;
    }
    if (this._debug) {
      console.debug('oidc signin called', args);
    }
    this._blocked = true;
    try {
      await this._userManager.signinRedirect(args);
    } catch(reason) {
      this._blocked = false;
      console.debug(`signin redirect failed : ${reason}`);
      throw reason;
    }
  }

  public signinSilent = async (args: ISigninSilentArgs = {}): Promise<User | null> => {
    if (this._blocked || !this._auto) {
      return null;
    }
    if (this._debug) {
      console.debug('oidc signin silent called', args);
    }
    try {
      return await this._userManager.signinSilent(args) as User;
    } catch(reason) {
      console.debug(`signin silent failed: ${reason}`);
      throw reason;
    }
  }

  public signout = async (args: ISignoutArgs = {}): Promise<void> => {
    if (this._debug) {
      console.debug('oidc signout called', args);
    }
    if (this._blocked) {
      return;
    }
    this._blocked = true;
    try {
      await this._userManager.signoutRedirect(args);
    } catch(reason) {
      this._blocked = false;
      console.debug(`signout redirect failed: ${reason}`);
      throw reason;
    }
  }

  public removeUser = (): Promise<void> => {
    return this._userManager.removeUser();
  }

  public withRequest = async (request: string): Promise<void> => {
    await this.removeUser();
    this._userManager.settings.extraQueryParams['request'] = request;
  }

  public isAuthenticated = (): boolean => {
    return !!this._user;
  }

  public isBlocked = (): boolean => {
    return !!this._blocked;
  }

  public processSigninResponse = async (): Promise<User> => {
    //console.debug('oidc processSigninResponse called', window.location.href);
    return await this._userManager.signinRedirectCallback() as User;
  }

  public processSigninSilentResponse = async (): Promise<void> => {
    //console.debug('oidc processSigninSilentResponse called', window.location.href);
    await this._userManager.signinSilentCallback();
  }

  public processSignoutResponse = async (): Promise<unknown> => {
    //console.debug('oidc processSignoutResponse called', window.location.href);
    this._auto = false;
    // NOTE(longsleep): The endsession state is always returned as query, but
    // oidc-client-ts expects it to use whatever we have set in the settings. As
    // that is fragment for us, we have to fake it.
    const url = window.location.protocol + '//' + window.location.host + window.location.pathname + '#' + window.location.search.substring(1);
    const response = await this._userManager.signoutRedirectCallback(url);
    // TODO(longsleep): Investigate if we should do anything with the error fields
    // in response. Is there a standard for those?
    return response.userState;
  }
}

export default Auth;
