import http from '../httpManage';

import { IChangePasswordData } from './types/Me';

export const meAPI = http.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation<void, IChangePasswordData>({
      query: (data) => ({
        url: `/me/changePassword`,
        method: 'POST',
        body: data,
      }),
    }),
    getProfilePhoto: build.query<string | null, string | null>({
      query: (size) => ({
        url: size ? `/me/photos/${size}/$value` : `/me/photo/$value`,
        validateStatus: (response) => {
          return [200, 404].includes(response.status);
        },
        responseHandler: async (response) => {
          if (response.status === 404) {
            return null;
          }
          const b = await response.blob();
          return URL.createObjectURL(b);
        },
        providesTags: [{ type: 'ProfilePhoto', id: size }, { type: 'ProfilePhoto', id: 'LIST' }],
      }),
      async onCacheEntryAdded(
        size,
        { cacheDataLoaded, cacheEntryRemoved }
      ) {
        const { data } = await cacheDataLoaded;
        if (data) {
          await cacheEntryRemoved;
          URL.revokeObjectURL(data);
        }
      },
    }),
    updateProfilePhoto: build.mutation<void, File>({
      query: (file) => ({
        url: `/me/photo/$value`,
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
        responseHandler: async () => undefined,
        invalidatesTags: [{ type: 'ProfilePhoto', id: 'LIST' }],
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useChangePasswordMutation,
  useGetProfilePhotoQuery,
  useLazyGetProfilePhotoQuery,
  useUpdateProfilePhotoMutation,
} = meAPI;

export default meAPI;
