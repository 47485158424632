import React, { MouseEvent } from 'react';

import {
  Box,
  Zoom,
} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

export type ScrollTopProps = {
  children: React.ReactElement;
};

export function ScrollTop(props: ScrollTopProps): JSX.Element {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

export default ScrollTop;
