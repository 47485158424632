import React, { useState, useCallback, FC } from 'react';

import Context, { defaultState, IMenuObject, CheckFunc } from './Context';

export interface Props {
  children: React.ReactNode;
  menus?: IMenuObject[];
}

export const MenuProvider: FC<Props> = ({
  children,
  menus,
}: Props) => {
  const [ mobileOpen, setMobileOpen ] = useState<boolean>(defaultState.mobileOpen);
  const [ checkContext, setCheckContext ] = useState<CheckFunc | undefined>(defaultState.checkContext);

  const toggleDrawer = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [ setMobileOpen, mobileOpen ]);

  const setCheckContextFunc = useCallback((cf?: CheckFunc) => {
    if (cf) {
      setCheckContext(() => cf);
    } else {
      setCheckContext(undefined);
    }
  }, [ setCheckContext ]);

  return (
    <Context.Provider
      value={{
        mobileOpen,
        toggleDrawer,
        checkContext,
        setCheckContext: setCheckContextFunc,
        menus: menus || defaultState.menus,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default MenuProvider;
