import React, { FC, useState, useEffect, useMemo } from 'react';

import { useAppDispatch } from '../../app/hooks';
import { setCredentials } from '../../features/auth/authSlice';

import { useConfig } from '../Config';

import Context from './Context';
import Auth, { UserManagerSettings } from './Auth';

const singleton: { auth?: Auth, settings?: UserManagerSettings } = {
  // NOTE(longsleep): Only initialize a single Auth per settings, ever. This
  // avoids double create when running in development mode as React triggers
  // useMemo twice in strict development mode to detect side effects.
};

export type Props = React.PropsWithChildren<{
  settings?: UserManagerSettings,
}>

export const AuthProvider: FC<Props> = ({
  children,
  settings,
}: Props) => {
  const config = useConfig();
  const auth = useMemo(() => {
    if (!singleton.auth || singleton.settings !== settings) {
      singleton.auth = new Auth(config, settings);
      singleton.settings = settings;
    }
    return singleton.auth;
  }, [ config, settings ]);

  const dispatch = useAppDispatch();

  const [ value, setValue ] = useState({...auth, user: auth.user})

  useEffect(() => {
    (auth as Auth).onUserChange = async (user) => {
      if (user === value.user) {
        return;
      }
      setValue({
        ...auth,
        user,
      });
      dispatch(setCredentials(user ? { token_type: user.token_type, access_token: user.access_token } : null));
    };
  }, [ value, auth, dispatch, setValue ]);

  return (
    <Context.Provider
      value={value}
    >
      {children}
    </Context.Provider>
  )
}

export default AuthProvider;
