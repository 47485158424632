import React, { FC } from 'react';

import Context, { defaultState, IRouteObject } from './Context';

export interface Props {
  children: React.ReactNode;
  routes?: IRouteObject[];
  basename?: string;
}

export const RoutesProvider: FC<Props> = ({
  children,
  routes,
  basename,
}: Props) => {

  return (
    <Context.Provider
      value={{
        routes: routes || defaultState.routes,
        basename: basename || defaultState.basename,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default RoutesProvider;
