import React, { createContext } from 'react';

import { RouteProps } from 'react-router';

export interface IRouteObject extends Omit<RouteProps, "children"> {
  breadcrumb?: React.ComponentType | React.ElementType | string | null,
  children?: IRouteObject[],
}

export interface IContext {
  routes: IRouteObject[];
  basename?: string;
}

export const defaultState: IContext = {
  routes: [],
  basename: '',
};

export const Context = createContext<IContext>(defaultState);

export default Context;
