import React, { useState } from 'react';

import { Navigate, NavigateProps } from 'react-router-dom';

import { useAuth } from '../../providers/Auth';
import { LoadPage } from '../../components/LoadPage';

import { Role } from './types/Roles';
import { useRoles, useExactlyOnce } from './hooks';

export const IndexPage = (): JSX.Element => {
  const { isAuthenticated, signinSilent } = useAuth();
  const authenticated = isAuthenticated();
  const { roles } = useRoles();

  const [ loading, setLoading ] = useState(!authenticated);

  useExactlyOnce(() => {
    if (!authenticated) {
      signinSilent().catch(() => null).then(() => {
        setLoading(false);
      });
    }
  });

  if (loading) {
    return <LoadPage/>;
  }

  // Redirect based on authentication and roles.
  const target: NavigateProps = {
    to: (roles === Role.User || roles === Role.Guest) ? 'account' : 'domains',
    replace: true,
  }
  if (!authenticated) {
    // Over
    target.to = 'signin-required';
    target.state = {
      end: true,
    };
  }

  return <Navigate {...target}/>;
}

export default IndexPage;
