import React, { createContext } from 'react';

import {
  useLocation,
} from 'react-router-dom';
import { PathRouteProps, PathMatch } from 'react-router';
import {
  ListItemButtonProps,
} from '@mui/material';

import { Permission } from '../../features/auth/types/Permissions';
import { RoleSet } from '../../features/auth/types/Roles';

type Location = ReturnType<typeof useLocation>;

export interface SecondaryActionWithItemProps extends Pick<PathRouteProps, "path"> {
  match: PathMatch,
  location: Location,
}

export interface IMenuObject extends Omit<PathRouteProps, "children"> {
  primary?: string | null,
  to?: string,
  icon?: React.ReactNode,
  props?: Partial<ListItemButtonProps>,
  children?: IMenuObject[],
  secondaryAction?: React.ComponentType<SecondaryActionWithItemProps>,
  requiredPermission?: Permission,
  requiredRole?: RoleSet,
}

export type CheckFunc = (item: IMenuObject) => boolean;

export interface IContext {
  mobileOpen: boolean;
  toggleDrawer?: () => void;
  checkContext?: CheckFunc;
  setCheckContext?: (checkFunc: CheckFunc) => void;
  menus: IMenuObject[];
}

export const defaultState: IContext = {
  mobileOpen: false,
  menus: [],
};

export const Context = createContext<IContext>(defaultState);

export default Context;
