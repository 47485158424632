import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: '',
});

export default createApi({
  reducerPath: 'config',
  baseQuery,
  endpoints: () => ({}),
});
